import React, { useContext } from "react";
import MenuContainer from "../../components/MenuContainer/index.js";
import silversea_logo from "../../images/svg/logo-silversea.svg";
import { REDIRECT_LOGO } from "../../utils/redirectPaths";
import { Link } from "react-router-dom";
import { Layout, Avatar, Col, Row, Dropdown, Menu } from "antd";
import {UserOutlined} from "@ant-design/icons";
import {AppContext, logout} from "../App";
import "../../css/global.scss"

const  { Header, Sider, Content } = Layout;

const MainLayout = props => {
  const { children } = props;
  const context = useContext(AppContext);

  const showEmailInAvatarBox = false;


  const accountMenu = (
    <Menu>
      <Menu.Item key="logout">
        <div onClick={logout}>Logout</div>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="MainLayout">
      <Header
        style={{
          background: "#323234",
          borderBottom: "1px solid black",
          position: "fixed",
          padding: "0 25px 0 25px",
          left: 0,
          top: 0,
          right: 0,
          height: 90,
          zIndex: 999
        }}
      >
        <Link to={REDIRECT_LOGO}>
          <img src={silversea_logo} alt="SILVERSEA" style={{marginTop:"15px"}} width="270" height="49"/>
        </Link>
        <div style={{float: "right", marginTop: "5px"}}>
            <Dropdown overlay={accountMenu} placement="bottomCenter">
              <Avatar style={{ backgroundColor: "#001529", cursor: "pointer" }} icon={<UserOutlined/>} />
            </Dropdown>
            <div style={{marginTop: "22px",float: "right",height: "30px", lineHeight: "8px", padding: "0", marginLeft: "10px" , color: "#ffffff"}}>
              <p><b>{context.username}</b></p>
              <p>{context.email}</p>
            </div>
        </div>
      </Header>
      <Layout>
        <Sider
          width="200px"
          theme="dark"
          style={{
            borderRight: "1px solid black",
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 90,
            marginRight: 5
          }}
        >
        <MenuContainer />
        </Sider>
        <Layout style={{ marginLeft: 200, padding: "0 5px", background: "#fff" }}>
          <Content
            style={{
              background: "#fff",
              marginTop: 100,
              padding: 40,
              minHeight: "calc(100vh - 64px)",
              minWidth: "calc(100vh - 64px)"
            }}>
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default MainLayout;
