import React, {Fragment, useCallback, useEffect, useState} from "react";
import constants, {topic} from "../constants/constants";
import {menuRoutes} from "../routing/menuRoutes";
import {ThemeProvider} from "@mui/material";
import AntdConfigProvider from "../custom/components/antd/AntdConfigProvider";
import {Redirect, Route, Switch} from "react-router-dom";
import {ErrorBoundary} from "../utils";
import MainLayout from "./MainLayout/MainLayout";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import {RouteWrapper} from "../routing/RouteWrapper";
import appStaticData, {updateAppStaticData} from "../appStaticData";
import PublishSubscribe from "publish-subscribe-js";

// React context of the app
export const AppContext = React.createContext();

export const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/demetra/login");
};


const App = (props) => {
    const appPersistentData = appStaticData();
    const sessionHandyData = getSession();
    const appContextData = {...appPersistentData, ...sessionHandyData};
    const [i18nInitialized, setI18nInitialized] = useState(false);
    const [appMenuRoutes, setAppMenuRoutes] = useState();

    const onI18nInitialized = useCallback((i18nInstance) => {
        setI18nInitialized(true);
        if (!appPersistentData.menuRoutes) {
            appPersistentData.menuRoutes = menuRoutes(appContextData);
            updateAppStaticData(appPersistentData);
            appContextData.menuRoutes = appPersistentData.menuRoutes;
        }
        setAppMenuRoutes(appPersistentData.menuRoutes);
    }, [i18nInitialized, appMenuRoutes]);

    useEffect(()=> {
        PublishSubscribe.subscribe(topic.i18n.initialized, onI18nInitialized);
    }, []);

    // jwt token transformation to get user and enabled modules
    function getSession() {
        const authToken = sessionStorage.getItem("authToken");
        const username = sessionStorage.getItem("username");
        const email = sessionStorage.getItem("email");
        const enabledModules = sessionStorage.getItem("enabledModules");
        const enabledFunctions = sessionStorage.getItem("enabledFunctions");

        let sessionData;
        try {
            if (username && enabledModules && enabledFunctions) {
                sessionData = {
                    username: username,
                    email: email,
                    enabledModules: enabledModules,
                    enabledFunctions: enabledFunctions,
                    authToken: authToken
                };
            } else if (authToken) {
                const base64Url = authToken.split(".")[1];
                const base64 = base64Url.replace("-", "+").replace("_", "/");

                let session = JSON.parse(window.atob(base64));

                sessionData = {
                    username: session.given_name,
                    email: session.email,
                    enabledModules: session.realm_access.roles,
                    enabledFunctions: constants.all,
                    authToken: authToken
                }

            }
        } catch (error) {
            alert(error);
        }
        return sessionData;
    }

    function homepageVisibile() {
        return appMenuRoutes && appMenuRoutes.length === 1;
    }

    function flatMenuArrayDef(route){
        let myArray = [...route.routes];

        route.routes.map((s,i)=>{
            if(s.sub){
                s.sub.map((x,c)=>{
                    myArray.push(x);
                })
            }
        });

        return myArray;
    }


    return (
        <AntdConfigProvider>
            {i18nInitialized &&
                <ThemeProvider theme={appPersistentData.muiTheme}>
                    <ErrorBoundary>
                        <React.Suspense fallback={appContextData.i18n.t("titleCase.loading") + "..."}>
                            <Switch>
                                <Route path="/" render={() => (
                                    getSession() ? (
                                        <AppContext.Provider value={appContextData}>
                                            {appMenuRoutes &&
                                                <MainLayout>
                                                    {
                                                        homepageVisibile() &&
                                                            <Route path="/demetra" exact component={HomePage}/>
                                                    }
                                                    {
                                                        appMenuRoutes.map((route, i) => {
                                                            return (
                                                                <Fragment key={"route_" + i}>
                                                                    <RouteWrapper key={i} exact {...flatMenuArrayDef(route)} />
                                                                    {
                                                                        flatMenuArrayDef(route) && flatMenuArrayDef(route).map((subroute, j) => {
                                                                            return <RouteWrapper key={j} exact {...subroute} />;
                                                                        })
                                                                    }
                                                                </Fragment>
                                                            );
                                                        })
                                                    }
                                                </MainLayout>
                                            }
                                        </AppContext.Provider>
                                    ) : (
                                        <>
                                            <Route path="/demetra/login" exact component={() => (<LoginPage t={appContextData.i18n.t}/>)}/>
                                            <Redirect to="/demetra/login"/>
                                        </>
                                    )
                                )}/>
                            </Switch>
                        </React.Suspense>
                    </ErrorBoundary>
                </ThemeProvider>
            }
        </AntdConfigProvider>
    );
}

export default App;