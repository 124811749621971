import * as PageContainer from "../pages";
import {routesPaths} from "./routesPaths";

export function menuRoutes(appContextData) {
    const t = appContextData.i18n.t;
    let routes = [];

    if (t) {

        const transactions = {
            path: routesPaths.transactionsDashboard,
            name: "Transaction",
            component: PageContainer.TransactionsDashboard,
            routes: [
                {
                    path: routesPaths.transactionsDashboard,
                    name: "Search",
                    id: "cTransactions",
                    component: PageContainer.TransactionsDashboard
                },
                
            ]
        };

        const paymentPage = {
            path: routesPaths.uploadPayment,
            name: "Payment",
            id: "uploadTrx",
            component: PageContainer.UploadPaymentContainer
        }

        const creditPage = {
            path: routesPaths.uploadCredit,    
            name: "Credit",
            id: "uploadCredit",
            component: PageContainer.UploadCreditContainer
        };

        if(appContextData.enabledModules.includes("CREDIT")){
            transactions.routes.push(creditPage);
        }
        if(appContextData.enabledModules.includes("PAYMENT")){
            transactions.routes.push(paymentPage);
        }

        const users = {
            path: routesPaths.usersDashboard,
            name: "Users",
            component: PageContainer.UsersDashboard,
            routes: [
                {
                    path: routesPaths.usersDashboard,
                    name: "Manage",
                    id: "cUsers",
                    component: PageContainer.UsersDashboard
                },
                {
                    path: routesPaths.createUser,
                    name: "Add",
                    id: "addUser",
                    component: PageContainer.UserCreate
                }
            ]
        };

        const statistic = {
            path: routesPaths.usersStatistics,
            name: "Statistic",
            component: PageContainer.UsersStatistics,
            routes: [
                {
                    path: routesPaths.usersStatistics,
                    name: "Dashboard",
                    id: "statistic",
                    component: PageContainer.UsersStatistics
                }
            ]
        };

        const routesOrder = [statistic.name,transactions.name,users.name];

        if(appContextData.email.includes("francesco.barbuto@emeal.nttdata.com")){
            routes.push(statistic);
        }

        if (appContextData.enabledModules.includes("VIEW")) {
            routes.push(transactions);
        }

        if (appContextData.enabledModules.includes("USER")){
            routes.push(users);
        }

        let newRoutes = routesOrder.map(el => routes.find(element => element.name === el) || {});
        routes = newRoutes.filter(value => Object.keys(value).length !== 0);
    }

    return routes;
}
