import React, {useState} from "react";
import "./LoginPage.scss";
import toyota_logo from "../images/svg/logo-silversea.svg";
import axios from 'axios';
import qs from 'qs';
import {DASHBOARD_BFF_PROFILE_API,DASHBOARD_BFF_CHANGE_PASSWORD_API, LOGIN_URL, GRANT_TYPE, CLIENT_ID, CLIENT_SECRET} from '../utils/api';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {routesPaths} from "../routing/routesPaths";
import { dashboardAxiosInstance } from "../custom/components/axios/AxiosInstance";
import { DASHBOARD_BFF_API } from "../utils/api";
import { use } from "i18next";

const LoginPage = (props) => {
    const {t} = props;
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [repeatPassword, setRepeatPassword] = useState(null);
    const [setUpPassword , setSetUpPassword] = useState(false);

    const loginAddresses = {
        loginUrl: LOGIN_URL,
        loginProfile: DASHBOARD_BFF_PROFILE_API
    };

    const loginData = {
        grant_type: GRANT_TYPE,
        username: username,
        password: password,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET
    };

    function handleFormInputs(e, id) {
        if (id === "username") {
            setUsername(e.target.value);
        }
        if (id === "password") {
            setPassword(e.target.value);
        }
        if (id === "repeatPassword") {
            setRepeatPassword(e.target.value);
        }
    }
    
    function handleEnterKeyPress(event) {
        if (event.key === "Enter") {
            loginAuth();
        }
    }

    async function loginAuth() {
        setLoading(true);
        if (!username || !password) {
            alert(t("error.fillInLoginFormFirst", {ns: "messages"}))
        } else {
            await axios({
                url: loginAddresses.loginUrl,
                method: "POST",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                data: qs.stringify(loginData)
            }).then((response) => {
                let jwt = response.data.access_token;
                sessionStorage.setItem("authToken", jwt);
                retrieveProfiles(jwt);
            }).catch((error) => {
                console.log(error.response.status);
                if(error.response.data.error_description === "Account is not fully set up" && error.response.status === 400){
                    alert("Please set your password");
                    setSetUpPassword(true);
                }else{
                    const s401 = error.response && error.response.status === 401 && 401;
                    if (s401) {
                        alert("Auth failed");
                    } else {
                        alert("Service unavailable" + ": " + error.response);
                    }
                }
            })
        }
        setLoading(false);
    }

    async function retrieveProfiles(jwt) {
        await dashboardAxiosInstance.get(loginAddresses.loginProfile, {
            headers: {'Authorization': `Bearer ${jwt}`}
        }).then((response) => {
            sessionStorage.setItem("enabledModules", response.data.modulesEnabled);
            sessionStorage.setItem("enabledFunctions", response.data.functionsEnabled);
            sessionStorage.setItem("username", response.data.username);
            sessionStorage.setItem("email", response.data.email);
            
            if(response.data.email === "francesco.barbuto@emeal.nttdata.com"){
                window.location.replace(routesPaths.usersStatistics);                
            }else{
                window.location.replace(routesPaths.transactionsDashboard);
            }
        }).catch((error) => {
            alert(t("error.errCallingProfileService", {ns: "messages"}));
        });

    }

    function setUpNewPassword(){
        if(password === repeatPassword){
            setLoading(true);
            var axios = require('axios');

            const encodedPassword = Buffer.from(password).toString('base64');

            var config = {
              method: 'put',
              url: `${DASHBOARD_BFF_CHANGE_PASSWORD_API}?username=${username}&password=${encodedPassword}`,
              headers: { 
                'accept': 'application/json'
              }
            };
            
            axios(config).then(function (response) {
              setSetUpPassword(false);
              alert("Password update success");
              setLoading(false);
            }).catch(function (error) {
              console.log(error);
              setLoading(false);
            });
        }else{
            alert("You have entered different passwords!");
        }
    }

    return (
        <div className="login-page" style={{borderRadius: "5px",backgroundColor:"#323234"}}>
            <div className="login-page_form-container" style={{backgroundColor:"#323234"}}>
                <img width="350px" height="64px" src={toyota_logo} alt="toyota-logo"/>
                {!setUpPassword && 
                    <Grid container
                        style={{marginTop: "20px",
                                backgroundColor: "#FFFFFF", 
                                borderRadius : "5px", 
                                paddingRight: "7px", 
                                paddingBottom:"8px"
                            }}
                        spacing={1} 
                        direction="column" 
                        justifyContent="space-between" 
                        alignItems="center"
                    >
                        <Grid container item xs={12}>
                                <TextField fullWidth id="outlined" label="Username" variant="outlined"
                                    onChange={(e) => handleFormInputs(e, "username")} onKeyPress={handleEnterKeyPress} />
                        </Grid>
                        <Grid container item xs={12}>
                            <TextField fullWidth
                                type="password"
                                id="outlined-password-input"
                                label="Password"
                                variant="outlined"
                                onChange={(e) => handleFormInputs(e, "password")}
                                onKeyPress={handleEnterKeyPress} />
                        </Grid>
                        <Grid container item xs={12}>
                            <Button variant="contained"
                                style={{backgroundColor:"#FFBB4D", color: "#323234",fontSize: "14px",letterSpacing: "1px"}}
                                loading={loading.toString()}
                                onClick={loginAuth}
                                fullWidth>
                                    <b>LOGIN</b>
                            </Button>
                        </Grid>
                    </Grid>
                }{setUpPassword && 
                    <Grid container
                        style={{marginTop: "20px",
                                backgroundColor: "#FFFFFF", 
                                borderRadius : "5px", 
                                paddingRight: "7px", 
                                paddingBottom:"8px"
                            }}
                        spacing={1} 
                        direction="column" 
                        justifyContent="space-between" 
                        alignItems="center"
                    >
                        <Grid container item xs={12}>
                                <TextField fullWidth type="password" id="outlined-password-input" label="Password" variant="outlined"
                                    onChange={(e) => handleFormInputs(e, "password")} onKeyPress={handleEnterKeyPress} />
                        </Grid>
                        <Grid container item xs={12}>
                            <TextField fullWidth type="password" id="outlined-password-input" label="Repeat Password" variant="outlined"
                                onChange={(e) => handleFormInputs(e, "repeatPassword")} onKeyPress={handleEnterKeyPress} />
                        </Grid>
                        <Grid container item xs={12}>
                            <Button variant="contained" loading={loading.toString()} onClick={setUpNewPassword} fullWidth
                                style={{backgroundColor:"#FFBB4D", color: "#323234",fontSize: "14px",letterSpacing: "1px"}}
                            >
                                <b>Set Up Password</b>
                            </Button>
                        </Grid>
                    </Grid>
                }
            </div>

        </div>
    )
};

export default LoginPage;