//const BASE_URL_PREVIEW = "/preview/nexi/dashboard/";
const APP_BASE_URL = "/demetra/"


//034-InfoFile
const REDIRECT_GENERIC_034_DETAIL_INPUT_FILE_NAME = APP_BASE_URL + "034-infofile/file";
const REDIRECT_GENERIC_034_DETAIL_OUTPUT_FILE_NAME = APP_BASE_URL + "034-infofile/file";
const REDIRECT_GENERIC_034_DETAIL_RESPONSE_FILE_NAME = APP_BASE_URL + "034-infofile/file";

const REDIRECT_INFOFILE_TRANSACTIONS_DETAIL_INPUT_FILE_NAME = APP_BASE_URL + "034-infofile/file";
const REDIRECT_INFOFILE_TRANSACTIONS_DETAIL_OUTPUT_FILE_NAME = APP_BASE_URL + "034-infofile/file";
const REDIRECT_INFOFILE_TRANSACTIONS_DETAIL_RESPONSE_FILE_NAME = APP_BASE_URL + "034-infofile/file";

//SEDA
const REDIRECT_MANDATE_SEDA_NEW_RULE_BUTTON = APP_BASE_URL + "seda/rulemanagment";

//SCT
const REDIRECT_BULK_SCT_DETAIL_INPUT_FILE_NAME = APP_BASE_URL + "sct/file";
const REDIRECT_BULK_SCT_DETAIL_TRANSACTION_TABLE_ROW_CLICK = APP_BASE_URL + "sct/tracking-transactions";

const REDIRECT_TRACKING_TRANSACTIONS_DETAIL_INPUT_BULK_MSG_ID = APP_BASE_URL + "sct/bulk";
const REDIRECT_TRACKING_TRANSACTIONS_DETAIL_OUTPUT_BULK_MSG_ID = APP_BASE_URL + "sct/bulk";
const REDIRECT_TRACKING_TRANSACTIONS_DETAIL_INPUT_FILE_NAME = APP_BASE_URL + "sct/file";
const REDIRECT_TRACKING_TRANSACTIONS_DETAIL_OUTPUT_FILE_NAME = APP_BASE_URL + "sct/file";

//SDD
const REDIRECT_BULK_SDD_DETAIL_INPUT_FILE_NAME = APP_BASE_URL + "sdd/file";
const REDIRECT_BULK_SDD_DETAIL_TRANSACTION_TABLE_ROW_CLICK = APP_BASE_URL + "sdd/tracking-transactions";

const REDIRECT_TRACKING_TRANSACTIONS_SDD_DETAIL_INPUT_BULK_MSG_ID = APP_BASE_URL + "sdd/bulk";
const REDIRECT_TRACKING_TRANSACTIONS_SDD_DETAIL_OUTPUT_BULK_MSG_ID = APP_BASE_URL + "sdd/bulk";
const REDIRECT_TRACKING_TRANSACTIONS_SDD_DETAIL_INPUT_FILE_NAME = APP_BASE_URL + "sdd/file";
const REDIRECT_TRACKING_TRANSACTIONS_SDD_DETAIL_OUTPUT_FILE_NAME = APP_BASE_URL + "sdd/file";

//P4I 
const REDIRECT_P4I_PAYMENT_TOOLS = APP_BASE_URL + "paymentGateway/paymentTools";
const REDIRECT_P4I_PRODUCTS = APP_BASE_URL + "paymentGateway/products";
const REDIRECT_P4I_SALES_CHANNELS = APP_BASE_URL + "paymentGateway/salesChannels";
const REDIRECT_P4I_DEFINITION_RULES = APP_BASE_URL + "paymentGateway/definitionRules";
const REDIRECT_P4I_PAYMENTS = APP_BASE_URL + "paymentGateway/payments";

//EVENT MANAGER
const REDIRECT_EM_SYSTEM = APP_BASE_URL + "eventManager/eventSystem";
const REDIRECT_EM_EVENT_CONFIGURATION = APP_BASE_URL + "eventManager/eventConfiguration";
const REDIRECT_EM_EVENT = APP_BASE_URL + "eventManager/event";

//TRANSACTION
const REDIRECT_NEW_PAYMENT = APP_BASE_URL+"upload/payment"

//USER
const REDIRECT_LIST_USER = APP_BASE_URL + "users/dashboard";
const REDIRECT_NEW_USER = APP_BASE_URL + "users/create";

//STATISTIC
const REDIRECT_STATISTIC_DASHBOARD = APP_BASE_URL + "statistics/dashboard";
const REDIRECT_LOGO = REDIRECT_STATISTIC_DASHBOARD;


export { 
            REDIRECT_LOGO,REDIRECT_GENERIC_034_DETAIL_INPUT_FILE_NAME, REDIRECT_GENERIC_034_DETAIL_OUTPUT_FILE_NAME, REDIRECT_GENERIC_034_DETAIL_RESPONSE_FILE_NAME,
            REDIRECT_INFOFILE_TRANSACTIONS_DETAIL_INPUT_FILE_NAME, REDIRECT_INFOFILE_TRANSACTIONS_DETAIL_OUTPUT_FILE_NAME, REDIRECT_INFOFILE_TRANSACTIONS_DETAIL_RESPONSE_FILE_NAME,
            REDIRECT_MANDATE_SEDA_NEW_RULE_BUTTON,REDIRECT_BULK_SCT_DETAIL_INPUT_FILE_NAME, REDIRECT_BULK_SCT_DETAIL_TRANSACTION_TABLE_ROW_CLICK, 
            REDIRECT_TRACKING_TRANSACTIONS_DETAIL_INPUT_BULK_MSG_ID, REDIRECT_TRACKING_TRANSACTIONS_DETAIL_OUTPUT_BULK_MSG_ID,REDIRECT_TRACKING_TRANSACTIONS_DETAIL_INPUT_FILE_NAME, 
            REDIRECT_TRACKING_TRANSACTIONS_DETAIL_OUTPUT_FILE_NAME,REDIRECT_BULK_SDD_DETAIL_INPUT_FILE_NAME, REDIRECT_BULK_SDD_DETAIL_TRANSACTION_TABLE_ROW_CLICK,
            REDIRECT_TRACKING_TRANSACTIONS_SDD_DETAIL_INPUT_BULK_MSG_ID,REDIRECT_TRACKING_TRANSACTIONS_SDD_DETAIL_OUTPUT_BULK_MSG_ID,REDIRECT_TRACKING_TRANSACTIONS_SDD_DETAIL_INPUT_FILE_NAME,
            REDIRECT_TRACKING_TRANSACTIONS_SDD_DETAIL_OUTPUT_FILE_NAME ,REDIRECT_P4I_PAYMENT_TOOLS, REDIRECT_P4I_PRODUCTS, REDIRECT_P4I_SALES_CHANNELS, REDIRECT_P4I_DEFINITION_RULES, 
            REDIRECT_P4I_PAYMENTS,REDIRECT_EM_SYSTEM, REDIRECT_EM_EVENT_CONFIGURATION, REDIRECT_EM_EVENT,REDIRECT_NEW_USER,REDIRECT_LIST_USER,REDIRECT_NEW_PAYMENT
        };