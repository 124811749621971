import { lazy } from "react";

const LoginPage = lazy(() => import(/* webpackChunkName: `LoginPage` */ `./LoginPage.js`));

//Payment4Insurance
const PGDashboard = lazy(() => import(/* webpackChunkName: `DashboardPaymentGateway` */ `./PaymentGateway/DashboardPaymentGateway.js`));
const PGPaymentTools = lazy(() => import(/* webpackChunkName: `PaymentTools` */ `./PaymentGateway/PaymentTools.js`));
const PGProducts = lazy(() => import(/* webpackChunkName: `Products` */ `./PaymentGateway/Products.js`));
const PGSalesChannels = lazy(() => import(/* webpackChunkName: `SalesChannels` */ `./PaymentGateway/SalesChannels.js`));
const PGPaymentsRequests = lazy(() => import(/* webpackChunkName: `PaymentsRequests` */ `./PaymentGateway/PaymentsRequests.js`));
const PGPaymentsExcecutions = lazy(() => import(/* webpackChunkName: `PaymentsExcecutions` */ `./PaymentGateway/PaymentsExcecutions.js`));
const PGPaymentsRiconciliation = lazy(() => import(/* webpackChunkName: `PaymentsRiconciliation` */ `./PaymentGateway/PaymentsRiconciliation.js`));
const PGDefinitionRules = lazy(() => import(/* webpackChunkName: `DefinitionRules` */ `./PaymentGateway/DefinitionRules.js`));
const PGAdmin = lazy(() => import(/* webpackChunkName: `Admin` */ `./PaymentGateway/Admin.js`));

// Event Manager
const EMEventSystem = lazy(() => import(/* webpackChunkName: `EventSystem` */ `./EventManager/EventSystem.js`));
const EMEventConfiguration = lazy(() => import(/* webpackChunkName: `EventConfiguration` */ `./EventManager/EventConfiguration.js`));
const EMEvent = lazy(() => import(/* webpackChunkName: `Event` */ `./EventManager/Event.js`));

// UsersDashboard
const UsersDashboard = lazy(() => import(/* webpackChunkName: `UsersDashboard` */ `./Users/UsersDashboard.js`));
const UserCreate = lazy(() => import(/* webpackChunkName: `UsersCreate` */ `./Users/UsersCreate.js`));

// TransactionsDashboard
const TransactionsDashboard = lazy(() => import(/* webpackChunkName: `TransactionsDashboard` */ `./Transactions/TransactionsDashboard.js`));
const UploadPaymentContainer = lazy(() => import(/* webpackChunkName: `TransactionsDashboard` */ `./Transactions/UploadPaymentContainer.js`));
const UploadCreditContainer = lazy(() => import(/* webpackChunkName: `TransactionsDashboard` */ `./Transactions/UploadCreditContainer.js`));
const Performance = lazy(() => import(/* webpackChunkName: `PerformanceConcessionarie` */ `./Performance/PerformanceConcessionarie.js`));

// UsersStatistics
const UsersStatistics = lazy(() => import(/* webpackChunkName: `UsersStatistics` */ `./Statistics/UsersStatistics.js`));


export  { 
            LoginPage,PGDefinitionRules, PGAdmin ,PGPaymentsRiconciliation,
            PGDashboard, PGPaymentTools, PGProducts, PGSalesChannels, 
            PGPaymentsRequests, PGPaymentsExcecutions, EMEventSystem, UserCreate,
            EMEventConfiguration, EMEvent,UploadPaymentContainer,UsersStatistics,
            UsersDashboard,TransactionsDashboard,Performance,UploadCreditContainer
        };
