import loadJSON from "./loadJSON";

const publicApi = loadJSON(`${process.env.PUBLIC_URL}/api/api.json`);
const BASE_URL = publicApi.BASE_URL;

// Login
const CLIENT_ID = publicApi.CLIENT_ID;
const CLIENT_SECRET = publicApi.CLIENT_SECRET;
const GRANT_TYPE = publicApi.GRANT_TYPE;
const LOGIN_PROFILE = BASE_URL + publicApi.LOGIN_PROFILE;
const LOGIN_URL = publicApi.LOGIN_URL;
const EASY_PAY_URL = publicApi.EASY_PAY_URL;
const APP_SECURITY_TOKEN = publicApi.APP_SECURITY_TOKEN;

// Event Manager
const EVENT_MANAGER_URL = publicApi.EVENT_MANAGER_URL;

// BFF
const DASHBOARD_BFF_API = BASE_URL + publicApi.DASHBOARD_BFF_API;
const DASHBOARD_BFF_CREDIT_API = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_CREDIT_API;
const DASHBOARD_BFF_PROFILE_API = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_PROFILE_API;
const DASHBOARD_BFF_PAGINATED_TRANSACTION_API = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_PAGINATED_TRANSACTION_API;
const DASHBOARD_BFF_TRANSACTION_DETAIL_API = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_TRANSACTION_DETAIL_API;
const DASHBOARD_BFF_REFOUND_ON_TRANSACTION_API = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_REFOUND_ON_TRANSACTION_API;
const DASHBOARD_BFF_NEW_PAYMENT_API = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_NEW_PAYMENT_API;
const DASHBOARD_BFF_REFUND = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_REFUND;
const DASHBOARD_BFF_TRANSACTION_LOG_API = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_TRANSACTION_LOG_API; 
const DASHBOARD_BFF_ADD_USER_API = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_ADD_USER_API;
const DASHBOARD_BFF_DELETE_USER_API = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_DELETE_USER_API;
const DASHBOARD_BFF_RESET_USER_API = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_RESET_USER_API;
const DASHBOARD_BFF_CHANGE_PASSWORD_API = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_CHANGE_PASSWORD_API;
const DASHBOARD_BFF_DOWNLOAD_CSV = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_DOWNLOAD_CSV;
const DASHBOARD_BFF_GET_USERS = BASE_URL+publicApi.APP_ID+publicApi.DASHBOARD_BFF_GET_USERS;


export {
    CLIENT_ID, CLIENT_SECRET, EASY_PAY_URL, GRANT_TYPE, LOGIN_PROFILE, LOGIN_URL,EVENT_MANAGER_URL,
    DASHBOARD_BFF_API,BASE_URL,DASHBOARD_BFF_CREDIT_API,DASHBOARD_BFF_PROFILE_API,DASHBOARD_BFF_PAGINATED_TRANSACTION_API,
    DASHBOARD_BFF_TRANSACTION_DETAIL_API,DASHBOARD_BFF_REFOUND_ON_TRANSACTION_API,DASHBOARD_BFF_NEW_PAYMENT_API,
    DASHBOARD_BFF_TRANSACTION_LOG_API,DASHBOARD_BFF_ADD_USER_API,DASHBOARD_BFF_DELETE_USER_API,DASHBOARD_BFF_RESET_USER_API,
    DASHBOARD_BFF_CHANGE_PASSWORD_API,DASHBOARD_BFF_REFUND,DASHBOARD_BFF_DOWNLOAD_CSV,DASHBOARD_BFF_GET_USERS,APP_SECURITY_TOKEN
};